<template>
    <div class="custome-search" ref="dropdownRef">
        <div class="input-group input-group input-group-merge ms-1 w-100">
            <span class="input-group-text">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search text-muted">
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
            </span>
          <input type="text" class="form-control"
            id="chat-search" v-model="searchTxt" @input="onSearch" @focus="onSearch"
            :placeholder="`Search ${title.toLowerCase()}`" aria-label="Search..."
            aria-describedby="chat-search"/>
        </div>

        <ul class="dropdown-search deep-shadow">
            <li
                @click="onHandleSelect(item, index)"
                v-for="(item, index) in searchResults" 
                :key="item.id" class="search-list-item">

                <div class="form-check">
                    <input v-model="item.isSelected" class="form-check-input" type="checkbox">
                    <label :class="item.isSelected && 'text-primary text-bold'" class="form-check-label">
                        {{ item.name }}
                    </label>
                </div>
            </li>
        </ul>
        
    </div>
</template>

<script setup>
import useDetectOutsideClick from '@/services/useDetectOutsideClick';
import { reactive, ref } from 'vue';
const props = defineProps(['title', 'onHandleSearch', 'searchResults', 'clearSearchResult', 'onSelectNote']);
const emit = defineEmits(['onHandleSearch', 'clearSearchResult', 'onSelectNote'])
const dropdownRef = ref(null)
const searchTxt = ref('');

useDetectOutsideClick(dropdownRef, () => {
    emit('clearSearchResult')
})

function onHandleSelect(item, index) {
    emit('onSelectNote', item);
}

const onSearch = () => {
    emit('onHandleSearch', searchTxt.value)
}
</script>

<style scoped>
.custome-search{
    position: relative;
    width: 50%;
}
.search-list-item:last-child {
    border: none;
}
.search-list-item {
    display: flex;
    align-items: center;
    padding: 5px;
    margin: 5px;
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;
}
.search-list-item:hover {
    background: #f9f7f7;
}
.dropdown-search {
    position: absolute;
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    left: 4%;
    padding-left: 0;
    background: #ffff;
    z-index: 99999;
}

</style>