<template>
    <div class="modal fade show add-note-modal" aria-labelledby="addNewCardTitle">
        <div class="modal-dialog modal-dialog-centered modal-lg" ref="admissionModalRef">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between align-items-center px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{ 'Add ' + note.name }}</h1>
                            <NoteSearch 
                                :title="note.name"
                                @onHandleSearch="onHandleSearch"
                                :searchResults="formattedSearchResults"
                                @clearSearchResult="clearSearchResult"
                                @onSelectNote="onSelectNote"
                            />
                            <button @click="closeModal" type="button" class="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                    </div>
                </div>

                <hr class="mt-0">
                
                <div class="modal-body">

                    <div class="row gy-2 px-2">

                        <div class="col-12">
                            <div class="card">
                                <div class="card-header" v-if="!isHideTab">
                                    <button type="button" class="btn" @click="onChangeTab('single')" :class="selectedTab === 'single' && 'bg'">
                                        {{ note.name }}
                                    </button>
                                    <button type="button" class="btn"  @click="onChangeTab('group')" :class="selectedTab === 'group' && 'bg'">
                                        {{ note.name }} groups
                                    </button>
                                </div>
                                <div class="card-body modal-card" v-show="selectedTab === 'single'">
                                    <button
                                        v-for="(preNote, index) in note.preAddedNotes" :key="preNote.id" 
                                        @click="onSelectNote(preNote, index)"
                                        class="btn cancel-subscription mt-1 waves-effect round med-title" 
                                        :class="preNote.isSelected ? 'btn-primary' : 'btn-outline-primary'">
                                        <i class="fas fa-check"></i>
                                        {{ preNote.name }}
                                    </button>
                                </div>
                                <div class="card-body" v-show="selectedTab === 'group'">
                                    <h2 class="text-center">Select Group</h2>
                                    <div class="row">
                                        <div class="col-12">
                                            <label for="colFormLabel" class="col-form-label">Search By Template Name</label>
                                            <AsyncSelect
                                                placeholder="Select template name"
                                                v-model="groupTemplate"
                                                :additional-query="{ template_type: note.value }"
                                                :api-service="fetchTemplateList"
                                                @option:selected="onSelectTemplate"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12" v-if="!isHideTab">
                            <div class="card">
                                <div class="card-header">
                                    Or Add new
                                </div>
                                <div class="card-body row">
                                    <div class="col-12 mb-2">
                                        <textarea v-model.trim="newValue" class="form-control"></textarea>
                                    </div>

                                    <div class="col-12 text-center">
                                        <button :disabled="!Boolean(newValue)" type="button" @click="submit" class="btn btn-primary me-1 waves-effect waves-float waves-light">
                                            <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                            <i class="fas fa-plus"></i>
                                            Add new
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Loader v-if="loading"/>
    </div>
</template>

<script setup>
import { computed, reactive, ref } from "vue";
import { useStore } from "vuex";
import NoteSearch from "./NoteSearch.vue";
import handleHospital from '@/services/modules/hospital'
import { useRoute } from "vue-router";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import Loader from '@/components/atom/LoaderComponent'
import { useDebouncedSearch } from '@/services/utils/global.js'

const store = useStore();
const $route = useRoute();
const props = defineProps({
    note: Object,
    modalState: {
        type: String,
        default: "isModalOpen"
    },
    noteType: {
        type: String,
        default: ''
    }
})

const emit = defineEmits([])

const searchTxt = ref('');
const searchResults = ref([]);
const newNotes = reactive([]);
const admissionModalRef = ref(null);
const groupTemplate = ref(null);

const isSpecialNote = computed(() => {
    return props.note.value === 'investigation_history' || props.noteType === 'group-template';
})

const isHideTab = computed(() => {
    return props.noteType === 'group-template';
})

const formattedSearchResults = computed(() => {
    return searchResults.value.map(item => {

        item.isSelected = false;

        if(isSpecialNote.value && props.note.selectedNotes.some(note => note.id === item.id)){
            item.isSelected = true;
            return item;
        }

        if(props.note.selectedNotes.includes(item.name)){
            item.isSelected = true;
        }
        return item;
    })
})

const loading = ref(false)
const newValue = ref('');
const selectedTab = ref('single');
const { notesByType, fetchTemplateList, fetchInvestigationHeads, fetchSingleTemplate } = handleHospital()

const getNotes = async () => {
    if(!searchTxt.value) return;
    let query = getQuery();

    if(props.note.value === 'investigation_history'){
        return getInvestigationHistory(query)
    }

    await getCommonNotes(query)
}

const onSelectTemplate = async(template) => {
    let query = getQuery();
    
    loading.value = true;

    await fetchSingleTemplate(query, template.id).then(res => {
        if (res.status){
            setItems(res.data);
            closeModal();
        }
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false;
    })
}

const setItems = (products) => {
    for (const item of products) {
        onSelectNote(item);
    }
}

const getInvestigationHistory = async (query) => {
    query += `&q=${searchTxt.value}`;
    await fetchInvestigationHeads(query).then((res) => {
        if(res.status) searchResults.value = res.data;
    })
}

const getCommonNotes = async (query) => {
    query += `&searchTxt=${searchTxt.value}`;
    await notesByType(query, props.note.value).then((res) => {
        if(res.status) searchResults.value = res.data;
    })
}

const { handleSearch } = useDebouncedSearch(getNotes);

const onHandleSearch = async(search) => {
    if(!search){
        clearSearchResult();
        return;
    };
    searchTxt.value = search;
    handleSearch();
}

const clearSearchResult = () => {
    searchResults.value.length = 0;
}

function getQuery() {
    let query = `?company_id=${$route.params.companyId}`;
    query += `&template_type=${props.note.value}`;
    return query;
}

const onChangeTab = (tab) => {
    selectedTab.value = tab;
}

const insertOrRemoveCommonNotes = (note) => {
    const newIndex = newNotes.findIndex(item => {
        if(isSpecialNote.value){
            return item.id === note.id
        }
        return item === note.name;
    });

    if (newIndex !== -1) {
        newNotes.splice(newIndex, 1);
    }

    if (newIndex < 0) {
        const newNote = isSpecialNote.value ? note : note.name;
        newNotes.push(newNote)
    }

    const preAddedNote = props.note.preAddedNotes.find(item => item.name === note.name);
    if(preAddedNote){
        preAddedNote.isSelected = note.isSelected;
    }
}

const onSelectNote = (note) => {
    note.isSelected = !note.isSelected;
    insertOrRemoveCommonNotes(note);
    saveNotes();
}


const setInitialValue = () => {
    newNotes.length = 0;
    for(let i = 0; i < props.note.selectedNotes.length; i++){
        const preAddedNote = props.note.preAddedNotes.find(item => {
                if(isSpecialNote.value){
                    return item.id === props.note.selectedNotes[i].id;
                }
               return item.name === props.note.selectedNotes[i];
            });

        if(preAddedNote){
            preAddedNote.isSelected = true;
        }

        newNotes.push(props.note.selectedNotes[i]);
    }
}

setInitialValue();

const closeModal = () => {
    store.state[props.modalState] = false;
}

const saveNotes = () => {
    props.note.selectedNotes.length = 0;
    props.note.selectedNotes.push(...newNotes)
    
}

const submit = () => {
    if (newValue.value) {
        props.note.selectedNotes.push(newValue.value)
        props.note.newNotesByDoctor.push(newValue.value)
    }
    closeModal();
}

</script>


<style scoped>
.modal-body{
    min-height: 400px;
}
.card-header {
    padding-top: 0%;
}
.card-header button {
    color: #7367f0;
    padding-left: 12%;
    padding-right: 12%;
    font-weight: bold;
}

.bg {
    background: aliceblue !important;
}
.note-search{
    width: 50%;
}
.med-title{
    padding: 0.486rem 1.5rem;
}
.modal-card{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-height: 280px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.add-note-modal {
    display: block; 
    background: #1c1d1c33
}
</style>